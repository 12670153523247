<template>
  <div>
    <SpinnerLogo v-if="loading" class="pt-5" />
    <div v-else-if="error"></div>
    <div v-else>
      <TabTitle class="mb-4">
        <template>Dashboard</template>
        <template #sub-title
          >Select a target and see the current progress towards it. Targets will also be shown elsewhere within Etainabl, such as in PDF
          reports.</template
        >
      </TabTitle>
      <div class="row">
        <div class="col-md-2">
          <FormGroup id="category" v-model="selectedTargetId" label="Select a target" :options="targetOptions" type="select" alt />
        </div>
      </div>

      <!-- <apexchart class="mb-4" type="line" height="350" :options="pathwayChartOptions" :series="pathwaySeries" /> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '@/components/FormGroup';
import SpinnerLogo from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'TargetsList',
  components: {
    FormGroup,
    SpinnerLogo,
    TabTitle
  },
  data() {
    return {
      error: null,
      loading: true,
      selectedTargetId: null
    };
  },
  computed: {
    ...mapGetters({
      targets: 'target/targets'
    }),
    targetOptions() {
      return this.targets.map(target => ({
        label: target.name,
        value: target._id
      }));
    }
  },
  async mounted() {
    this.loading = true;

    await this.listTargets();

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listTargets: 'target/list'
    })
  }
};
</script>
